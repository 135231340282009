import wrapText from './wrapText.js';
import galleryImages from './galleryImages2.js';
import ColorThief from '../../node_modules/colorthief/dist/color-thief.mjs'

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {

    if (arguments.length === 2) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
    }

    // default offset is center
    offsetX = typeof offsetX === "number" ? offsetX : 0.5;
    offsetY = typeof offsetY === "number" ? offsetY : 0.5;

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.width,
        ih = img.height,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, cw, ch, ar = 1;

    // decide which gap to fill    
    if (nw < w) ar = w / nw;                             
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
}

function textArranger(context, text, fontSize, boxX, boxY, boxW, boxH, direction, alignment, separator, maxNumLines=3) {

console.log(fontSize, boxX, boxY, boxW, boxH, direction, alignment);
    var words = text.split(separator);
    const numWords=words.length;
    const numLines=randomIntFromInterval(numWords === 1 ? 1 : 2 ,Math.min(maxNumLines,numWords));
    var remNumWords=numWords-numLines;
    
    var x=boxX;
    if (alignment === 'center') x=boxX+boxW/2;
    if (alignment === 'right') x=boxX+boxW;

    var y=boxY;


    if (direction==="down") context.textBaseline = 'hanging';
    else if (direction==="up") {
        context.textBaseline = 'bottom';
        y=boxY+boxH-(numLines-1)*fontSize;
    } else {
        context.textBaseline = 'middle';
        y=boxY+ boxH/2-((numLines-1)/2)*fontSize;
    }

	console.log(words, x, y)

    for (let i=0; i < numLines; i++) {
      var addWords=randomIntFromInterval(0,remNumWords);
        if (i==numLines-1) addWords=remNumWords; 
      var tempWords=words.slice(0,addWords+1);
      context.fillText(tempWords.join(separator), x, y);
      words.splice(0, addWords+1);
      remNumWords-=addWords;
      y+=fontSize;


    }

    return numLines;
}

function loadImage(url) {
  return new Promise(r => { let i = new Image(); i.onload = (() => r(i)); i.src = url; });
}

export default async function renderDesign(design, divEl, i,firstpass, separator) {

  const fontCombos=[
    {id:"OswaldQuattrocento", tiFont: "Oswald", stiFont:"Quattrocento", auFont:"Quattrocento"},
    {id:"cyborg", tiFont: "Roboto", stiFont:"Roboto", auFont:"Roboto"},
    {id: "LoraRoboto", tiFont: "Lora", stiFont:"Roboto", auFont:"Roboto"},
    {id: "PlayfairDisplaySourceSansPro", tiFont: "Playfair Display", stiFont:"Source Sans Pro", auFont:"Source Sans Pro"},
    {id: "Neucha", tiFont: "Neucha", stiFont:"Neucha", auFont:"Neucha"},
    {id: "News", tiFont: "News Cycle", stiFont:"News", auFont:"News"},
    {id: "lux", tiFont: "Nunito Sans", stiFont:"Nunito Sans", auFont:"Nunito Sans"},
    {id: "sketch", tiFont: "'Cabin Sketch', cursive", stiFont:"Neucha", auFont:"Neucha"},
    {id: "expletus", tiFont: "Expletus Sans", stiFont:"Hind", auFont:"Hind"},
     {id: "Bubblegum", tiFont: "Bubblegum Sans", stiFont:"Open Sans", auFont:"Open Sans"},
     {id: "Mulish", tiFont: "Mulish", stiFont:"Space Mono", auFont:"Space Mono"},
     
  ];

const themes=[
    {id:"white", background: "#FFFFFFFF", primaryText: "#000000FF", secondaryText: "#000000FF"},
  //  {id:"lux", background: "#FFFFFFFF", primaryText: "#1a1a1a", secondaryText: "#1a1a1a", tiFont: "Nunito Sans", stiFont:"Nunito Sans", auFont:"Nunito Sans"},


  //  {id:"lightBlue",background: "#89ABE3FF", primaryText: "#FCF6F5FF", secondaryText: "#FCF6F5FF"},
   // {id:"slate",background: "#272b30", primaryText: "#aaa", secondaryText: "#aaa",  tiFont: "Roboto", stiFont:"Roboto", auFont:"Roboto"},

  //  {id:"yellow",background: "#FEE715FF", primaryText: "#101820FF", secondaryText: "#101820FF"},
    //{id:"quartz",background: "#e83283", primaryText: "#33b7e2", secondaryText: "white",  tiFont: "Oswald", stiFont:"Quattrocento", auFont:"Quattrocento"},

   // {id: "blackOrage", background: "#101820FF", primaryText: "#F2AA4CFF", secondaryText: "#F2AA4CFF"},
    {id: "black", background: "dark", primaryText: "white", secondaryText: "white"},
   // {id: "cyborg", background: "#060606", primaryText: "#2a9fd6", secondaryText: "white",  tiFont: "Roboto", stiFont:"Roboto", auFont:"Roboto"},
    //{id: "sketch", background: "white", primaryText: "#555555", secondaryText: "#555555", tiFont: "'Cabin Sketch', cursive", stiFont:"Neucha", auFont:"Neucha"},

];



const rgbNums=[100,110,120,130,140,150,160,170,180,190,200,210,220,230,240,250];
//const r=rgbNums[Math.floor(Math.random()*rgbNums.length)];
//const g=rgbNums[Math.floor(Math.random()*rgbNums.length)];
//const b=rgbNums[Math.floor(Math.random()*rgbNums.length)];
//design.r=r;
//design.g=g;
//design.b=b;


const fontCombo=fontCombos[Math.floor(Math.random()*fontCombos.length)];//fontComboIndex];

var canvas = document.createElement('canvas');

canvas.id = "designCanvas"+i;
canvas.width = design.canvasWidth;
canvas.height = design.canvasHeight;
canvas.className="border border-warning border-0"
//for safari
canvas.style="width: 100%;height: auto"
divEl.appendChild(canvas);

canvas = document.getElementById("designCanvas"+i);
var ctx = canvas.getContext("2d");
var background=design;
background.designId="designCanvas"+i;






/*const colorThief = new ColorThief();

const images=galleryImages.filter( (e) => e.fileName && e.fileName.slice(0,6)!=='square' && e.fileName.slice(0,8)!=='portrait');
var imgSrc=images[Math.floor(Math.random() * images.length)].fileName;
let img = await loadImage('/gallery/'+imgSrc);
const colors=colorThief.getColor(img);
console.log(colors);
background.imageWidth=img.width;
background.imageHeight=img.height;

//const colorIndex=Math.floor(Math.random()*colors.length);
const r=colors[0];
const g=colors[1];
const b=colors[2];

var theme={};
if (r+g+b > 300) {
theme=themes[0];
} else theme=themes[1];
*/

const theme=themes[Math.floor(Math.random()*themes.length)];//themeIndex];
design.theme=theme.id;
ctx.beginPath();


ctx.fillStyle=theme.background;
ctx.fillRect(0,0, background.canvasWidth, background.canvasHeight);

//console.log(img.src);


const w = canvas.width;
const h = canvas.height;

const size = Math.random()*100;
var radius = Math.random()*300; //slightly smaller than half the size
const columns = w / size;
const rows = h / size;





if (!firstpass) {





  const coloursB= ["#BABFD1", "#E8E9ED","#f9d5e5","#ffeead","#96ceb4"];
  const coloursW = [ "#FF7B9C", "#607196","#feb236","#b2ad7f","#034f84"];
  const x = Math.random();
  const numElements=Math.random()*3;
  const fillStroke=Math.random();

  if (fillStroke <=0.5) {
    if (theme.id=="white") {
      ctx.fillStyle = coloursB[Math.floor(coloursB.length * Math.random())];//"rgb("+r+","+g+","+b+")";//colours[Math.floor(colours.length * Math.random())];

    } else {
      ctx.fillStyle = coloursW[Math.floor(coloursW.length * Math.random())];//"rgb("+r+","+g+","+b+")";//colours[Math.floor(colours.length * Math.random())];

    }
  } else {
      if (theme.id=="white") {
        ctx.strokeStyle = coloursB[Math.floor(coloursB.length * Math.random())];//"rgb("+r+","+g+","+b+")";//colours[Math.floor(colours.length * Math.random())];
      } else {
        ctx.strokeStyle = coloursW[Math.floor(coloursW.length * Math.random())];//"rgb("+r+","+g+","+b+")";//colours[Math.floor(colours.length * Math.random())];

      }
    }
  
  for (let c = 0; c < numElements; c++) {

    ctx.beginPath();

    if (x < 0.33) {
      radius=Math.random()*1000;
      ctx.ellipse(
        Math.random()*w,//(c + 0.5) * size, // centre of column
        Math.random()*h,//(r + 0.5) * size, // centre of row
        radius,
        radius,
        0,
        0,
        2 * Math.PI
      );
    } else if (x < 0.66 && x >= 0.33) {
      ctx.rect(Math.random()*w,Math.random()*h, Math.random()*size,Math.random()*size);
    } else {
      ctx.rect(Math.random()*w, 0, Math.random()*size, h);
    }

    if (fillStroke <=0.5) {
      ctx.fill();
    } else {
      ctx.stroke();
    }
  }  
}



	//ctx.drawImage(img, design.imgX, design.imgY, img.width/img.height*design.imgH, design.imgH);//, img.height);

  //drawImageProp(ctx,img, design.imgX, design.imgY, design.imgW, design.imgH);

//img.src='/gallery/'+imgSrc;

var ti=background.ti;//title.title.trim();

ctx.textAlign = background.tiAlign;
ctx.fillStyle=theme.primaryText;
ctx.textBaseline=background.tiDirection;
background.tiFont=fontCombo.tiFont;
background.stiFont=fontCombo.stiFont;
background.auFont=fontCombo.auFont;
//ctx.letterSpacing = "10px";

if (background.tiFontEffect=='regular') {
  ctx.font = background.tiFontSize+'px '+background.tiFont;
} else {
  ctx.font = background.tiFontEffect+' '+background.tiFontSize+'px '+background.tiFont;
}



//var tiNumLines=textArranger(ctx, ti, background.tiFontSize, background.tiX, background.tiY, background.tiWidth, background.tiHeight, background.tiDirection, background.tiAlign, ' ', 5);

//console.log(background.tiX, background.tiY, background.tiWidth, background.tiFontSize, background.tiDirection, background.tiAlign, background.tiHeight);


//ctx.strokeRect(background.tiX,background.tiY, background.tiWidth, background.tiHeight);


[background.tiNumLines, background.tiMaxLineWidth]=wrapText(ctx, ti, background.tiX, background.tiY, background.tiWidth, background.tiFontSize*1.05, background.tiDirection, background.tiAlign, background.tiHeight, ' ');

//ctx.letterSpacing = "0px";


var sti=background.sti;//title.subtitle.trim();

ctx.textAlign = background.stiAlign;
ctx.fillStyle=theme.secondaryText;
ctx.textBaseline=background.stiDirection;

  if (sti.length > 0) {

if (background.stiFontEffect=='regular') {
  ctx.font = background.stiFontSize+'px '+background.stiFont;
} else {
  ctx.font = background.stiFontEffect+' '+background.stiFontSize+'px '+background.stiFont;
}

//var stiNumLines=textArranger(ctx, sti, background.tiFontSize/1.98, background.stiX, background.stiY, background.stiWidth, background.stiHeight, background.stiDirection, background.stiAlign, ' ', 2);

//ctx.strokeRect(background.stiX,background.stiY, background.stiWidth, background.stiHeight);

  [background.stiNumLines, background.stiMaxLineWidth]=wrapText(ctx, sti, background.stiX, background.stiY, background.stiWidth, background.stiFontSize, background.stiDirection, background.stiAlign, background.stiHeight, ' ');

  }

var au=background.au;
ctx.textAlign = background.auAlign;
ctx.fillStyle=theme.primaryText;

  if (au.length > 0) {


if (background.auFontEffect=='regular') {
  ctx.font = background.auFontSize+'px '+background.auFont;
} else {
  ctx.font = background.auFontEffect+' '+background.auFontSize+'px '+background.auFont;
}
//ctx.strokeRect(background.auX,background.auY, background.auWidth, background.auHeight);

//var auNumLines=textArranger(ctx, au, background.auFontSize, background.auX, background.auY, background.auWidth, background.auHeight, background.auDirection, background.auAlign, ', ',20);

 [background.auNumLines, background.auMaxLineWidth]=wrapText(ctx, au, background.auX, background.auY, background.auWidth, background.auFontSize, background.auDirection, background.auAlign, background.auHeight, separator);

  }
//console.log(background);

return background;

}
