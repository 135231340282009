//import bcBoxLayouts from './bcBoxLayouts.js';
//import vcBoxLayouts from './vcBoxLayouts.js';
//import pBoxLayouts from './postersBoxLayouts.js';
//import sBoxLayouts from './signageBoxLayouts.js';

//const boxLayouts=bcBoxLayouts.concat(vcBoxLayouts,pBoxLayouts,sBoxLayouts);

function splitBox(w, h, x, y, vOrH, s) {
  var boxes={box1:{}, box2:{}};
  if (vOrH==1) {
    boxes.box1.W=w*s;
    boxes.box1.H=h;
    boxes.box1.X=x;
    boxes.box1.Y=y;
    boxes.box2.W=w*(1-s);
    boxes.box2.H=h;
    boxes.box2.X=x+w*s;
    boxes.box2.Y=y;
  } else {
    boxes.box1.W=w;
    boxes.box1.H=h*s;
    boxes.box1.X=x;
    boxes.box1.Y=y;
    boxes.box2.W=w;
    boxes.box2.H=h*(1-s);
    boxes.box2.X=x;
    boxes.box2.Y=y+h*s;
  }
  return boxes;
}

function setAlignment(box, cW, strategy, ms, me) {
  var alignment;
  if (strategy==='out') {
    if (box.X==ms) {
      alignment='left';
    } else if (box.X!=ms && Math.abs(box.X+box.W-(cW-ms-me)) < 0.05) {
        alignment='right';
    } 
    else alignment='center';

  } else if (strategy=='center') {
    alignment='center';
  } else if (strategy=='left') {
    alignment='left';
  } else if (strategy=='right') {
    alignment='right';
  }
  else {
    if (box.X==ms && box.W==(cW-ms-me)) {
      alignment='center';
    } else if (box.X==ms) {
      alignment='right';
    } else alignment='left';
  }
  return alignment;

}

function setDirection(box, cH, strategy, mt, mb) {
  var direction;
  if (strategy==='out' || strategy=='left' || strategy=='right') {
    if (box.Y==mt && Math.abs(box.Y+box.H - (cH-mb)) < 0.05) {
      direction='middle';
    } else if ( Math.abs(box.Y+box.H - (cH-mb)) < 0.05) {
      direction='up';
    } else direction='middle';

  } else if (strategy=='center') {
    direction='middle';
  }  else {
    if (box.Y==mt && Math.abs(box.Y + box.H-(cH-mb)) < 0.05) {
      direction='middle';
    } else if (box.Y==mt) {
      direction='up';
    } else direction='down'; 
  }
  return direction;

}

function genBoxLayout(ti,sti,au,numElements, aspectRatio) {

  
  var p=Math.random()*0.1+0.01;
  const cW=1;
  const cH=1; 

  //const s=Math.random()*0.2+0.4;

  //const vOrH=Math.random() <= 0.5 ? 1 : 0;
  //const opp=Math.random() <= 0.5 ? 1 : 0;
  
  const boxes=[];
  const ms=Math.random()*0.1;
  const me=Math.random()*0.1;
  const mt=Math.random()*0.1;
  const mb=Math.random()*0.1;
  var boxW=cW-ms-me, boxH=cH-mt-mb, boxX=0+ms, boxY=0+mt;
  const ordering=Math.random() <= 0.2 ? 0 : 1;
  var vOrH=0;
  //console.log(numElements, cW, cH);
  if (aspectRatio>1) {
    if (numElements > 2) {
      vOrH=0.1;
    } else vOrH=0.3;
  } else vOrH=0.1;

  for (let i = 0; i < numElements-1+Math.floor(Math.random()*5); i++) { //Math.random()*4+3; i++) {
    var twoBoxes=splitBox(boxW, boxH, boxX, boxY, Math.random() <= vOrH ? 1 : 0, Math.random()*0.2+0.4);
    //console.log(twoBoxes);
    if (ordering) {
      boxes[i]=twoBoxes.box1;
      boxes[i+1]=twoBoxes.box2;
      boxW=twoBoxes.box2.W;
      boxH=twoBoxes.box2.H;
      boxX=twoBoxes.box2.X;
      boxY=twoBoxes.box2.Y;
    } else {
      boxes[i]=twoBoxes.box2;
      boxes[i+1]=twoBoxes.box1;
      boxW=twoBoxes.box1.W;
      boxH=twoBoxes.box1.H;
      boxX=twoBoxes.box1.X;
      boxY=twoBoxes.box1.Y;
    }
    
  }

  //console.log(boxes);
  
  //var boxes1 = splitBox(cW, cH, 0, 0, vOrH, s);
  //var boxes2 = splitBox(boxes1.box2.W, boxes1.box2.H, boxes1.box2.X, boxes1.box2.Y, opp ? 1-vOrH: vOrH, s);

  var boxLayout={ti:{},sti:{},au:{}};




  const orders=[
       // {img: 0, ti:1, sti:2, au:3},

    {ti:0, sti:1, au:2},
   // {ti:0, sti:1, au:3},
    //{ti:0, sti:1, au:4},

    //{ti:1, sti:0, au:2},
   // {ti:1, sti:0, au:3},
    //{ti:1, sti:2, au:0},
   // {ti:1, sti:2, au:3},

  ];



  const order=orders[Math.floor(Math.random()*orders.length)];
  const stratP=Math.random();
  const strat= (stratP <=0.33 ? 'out' : stratP <= 0.66 ? 'in' : 'center');
  
 var lastUsedIndex=-1;
 var numElementsPlaced=0;
  //const imgIndex=order.img;//Math.floor(Math.random()*(boxes.length-9));
  //boxLayout.img=boxes[imgIndex];
  if  (ti.length>0) {
    //const tiIndex=Math.floor(Math.random()*(boxes.length-(numElements-numElementsPlaced)-numElementsPlaced))+numElementsPlaced;//order.ti;//Math.floor(Math.random()*(boxes.length-9));
    const tiIndex=Math.floor(Math.random()*(boxes.length-(numElements-numElementsPlaced)-(lastUsedIndex+1)))+lastUsedIndex+1;
    lastUsedIndex=tiIndex;
    numElementsPlaced+=1;
    boxLayout.ti=boxes[tiIndex];//boxes1.box1;
    boxLayout.ti.align=setAlignment(boxLayout.ti,cW, strat, ms, me);
    boxLayout.ti.direction=setDirection(boxLayout.ti,cH, strat, mt,mb);
  }

  if (sti.length > 0) {
    //const stiIndex=Math.floor(Math.random()*(boxes.length-(numElements-numElementsPlaced)-numElementsPlaced))+numElementsPlaced;//Math.floor(boxes.length-1);//order.sti;//Math.floor(Math.random()*(boxes.length-tiIndex-6)+tiIndex);
    const stiIndex=Math.floor(Math.random()*(boxes.length-(numElements-numElementsPlaced)-(lastUsedIndex+1)))+(lastUsedIndex+1);
    numElementsPlaced+=1;
    lastUsedIndex=stiIndex;
    boxLayout.sti=boxes[stiIndex];//boxes2.box1;
    boxLayout.sti.align=setAlignment(boxLayout.sti,cW, strat, ms, me);
    boxLayout.sti.direction=setDirection(boxLayout.sti,cH, strat, mt, mb);  
//console.log(stiIndex);
  }

  if (au.length > 0) {
    //const auIndex=Math.floor(Math.random()*(boxes.length-(numElements-numElementsPlaced)-numElementsPlaced))+numElementsPlaced;//boxes.length-1;//order.au;//Math.floor(Math.random()*(boxes.length-stiIndex)+stiIndex);
    const auIndex=Math.floor(Math.random()*(boxes.length-(numElements-numElementsPlaced)-(lastUsedIndex+1)))+(lastUsedIndex+1);
    boxLayout.au=boxes[auIndex];//boxes2.box2;
  
    //console.log(auIndex);

    boxLayout.au.align=setAlignment(boxLayout.au,cW, strat, ms, me);
  
    //console.log(boxLayout.au, cH, my)
    boxLayout.au.direction=setDirection(boxLayout.au,cH, strat, mt, mb);
  

  }
  
  //if (strat=='in') p=0.04*Math.random()+0.01;

  boxLayout.p=p;
  boxLayout.strat=strat;
  /*boxLayout.ti.X+=p/2;
  boxLayout.ti.Y+=p/2;
  boxLayout.ti.W-=p;
  boxLayout.ti.H-=p;

  boxLayout.sti.X+=p/2;
  boxLayout.sti.Y+=p/2;
  boxLayout.sti.W-=p;
  boxLayout.sti.H-=p;

  boxLayout.au.X+=p/2;
  boxLayout.au.Y+=p/2;
  boxLayout.au.W-=p;
  boxLayout.au.H-=p;*/

  boxLayout.id=Math.random();

return boxLayout;




}


function getMinMaxLen(text) {

  var words=text.split(' ');
  var index=0;
  var maxLen=0;
  var minLen=100;
  for (let i = 0; i < words.length; i++) {
    if (words[i].length>maxLen) {maxLen=words[i].length;index=i;}
    if (words[i].length<minLen) {minLen=words[i].length;}

  }


  return [minLen, maxLen];

}

function getNumAuthors(au) {


au=(au.split( /,/ ).reduce( (a,b, i) => i %2 == 0 ? a + ";" + b : a + "," + b ) );
var authors=au.split(';');
const numAuthors=authors.length;
var authors2=[];
authors.forEach(au => {
  var temp=au.split(',');

  var auTemp='';
  if (temp[1]) {auTemp=temp[1].trim()+' '+temp[0].trim()} else {auTemp=temp[0].trim()}
  authors2.push(auTemp.trim());

});
au=authors2.join(', ');
return [au, numAuthors];

}

export default function genDesign(title,canvasWidth, canvasHeight,orientation,numElements) {
  var design={};
  const textCases=['proper'];//,'proper'];//['upper','proper'];//lower
  const tiFontSizes=[120,90,72,60,48,42,36,32,28,24,18,16,14,12];//Training --- [120,90, 72,60,48,42,36,32,24, 28];//120,90
  const stiFontSizes=[28,24,18,16,14,12];//Training --- [24];//[24]
  const auFontSizes=[12,14,16,18,24,28,32];//Training --- [18];//[18]
  const textAlignments=['left', 'center'];
  const textDirection=['up'];//['up','down','middle'];


  const fontEffects=["regular", "regular", "regular","bold"];//,"regular","regular", "bold","bold", "italic"];
  const dimsArray=[
    {cW: 420, cH: 594},
    {cW: 560, cH: 860},
    {cW: 460, cH: 610},
    {cW: 610, cH: 910},

  ];
  const dims=dimsArray[Math.floor(Math.random()*dimsArray.length)];
  design.canvasWidth=canvasWidth;
  design.canvasHeight=canvasHeight;
  
  design.ti=title.title.trim();
  design.sti=title.subtitle.trim();
  design.au=title.au.trim();

  //const filteredBoxLayouts=boxLayouts.filter((l)=>l.orientation==orientation && l.numElements==numElements);
  const boxLayout=genBoxLayout(design.ti, design.sti, design.au, numElements, canvasWidth/canvasHeight);//filteredBoxLayouts[Math.floor(Math.random()*filteredBoxLayouts.length)];
  //console.log(boxLayout);

  design.tiAlign=boxLayout.ti.align;//textAlignments[Math.floor(Math.random()*textAlignments.length)];
  design.stiAlign=boxLayout.sti.align;
  design.auAlign=boxLayout.au.align;
  design.tiCase=textCases[Math.floor(Math.random() * textCases.length)];
  design.tiFontEffect=fontEffects[Math.floor(Math.random()*fontEffects.length)];
  design.tiFontSize=tiFontSizes[Math.floor(Math.random()*tiFontSizes.length)]
  design.stiFontEffect="italic";//fontEffects[Math.floor(Math.random()*fontEffects.length)];
  design.stiFontSize=stiFontSizes[Math.floor(Math.random()*stiFontSizes.length)]
  design.stiCase='proper';
  design.auFontEffect="regular";//fontEffects[Math.floor(Math.random()*fontEffects.length)];
  design.auFontSize=auFontSizes[Math.floor(Math.random()*auFontSizes.length)]
  design.auCase='proper';

  design.boxLayoutId=boxLayout.id;
  design.p=boxLayout.p;

  const fontSizeCombos=[


   /* {ti:120, sti:24, au:18},
    {ti:90, sti:24, au:18},
    {ti:72, sti:24, au:18},
    {ti:60, sti:24, au:18},
    {ti:48, sti:24, au:18},
    {ti:36, sti:24, au:18},
    {ti:28, sti:24, au:18},
  
    {ti:24, sti:18, au:14},
    {ti:24, sti:18, au:24},
    {ti:24, sti:18, au:36},
    {ti:24, sti:18, au:48},
    {ti:24, sti:18, au:60},

    {ti:120, sti:18, au:24},
    {ti:90, sti:18, au:24},
    {ti:72, sti:18, au:24},
    {ti:60, sti:18, au:24},
    {ti:48, sti:18, au:24},
    {ti:36, sti:18, au:24},
    {ti:28, sti:18, au:24},

    {ti:120, sti:24, au:24},
    {ti:90, sti:24, au:24},
    {ti:72, sti:24, au:24},
    {ti:60, sti:24, au:24},
    {ti:48, sti:24, au:24},
    {ti:36, sti:24, au:24},
    {ti:28, sti:24, au:24},
*/
    {ti:120, sti:18, au:24},
    {ti:90, sti:18, au:24},
    {ti:72, sti:18, au:24},
    {ti:60, sti:18, au:24},
    {ti:48, sti:18, au:24},
    {ti:36, sti:18, au:24},
    {ti:28, sti:18, au:24},
   

    {ti:90, sti:24, au:18},
    {ti:72, sti:24, au:18},
    {ti:60, sti:24, au:18},
    {ti:48, sti:24, au:18},
    {ti:42, sti:18, au:14},
    {ti:36, sti:18, au:14},
    {ti:28, sti:18, au:14},
    {ti:22, sti:14, au:12},
    {ti:18, sti:12, au:10},

  /*  
    {ti:48, sti:24, au:24},
    {ti:48, sti:24, au:12},
    {ti:24, sti:24, au:24},
   {ti:90, sti:24, au:12},
    {ti:72, sti:24, au:12},
    {ti:60, sti:24, au:12},
    {ti:48, sti:24, au:12},
    {ti:42, sti:24, au:12},
    {ti:36, sti:24, au:12},
*/
  ];
  const fontSizeCombo=fontSizeCombos[Math.floor(Math.random()*fontSizeCombos.length)];
  design.tiFontSize=tiFontSizes[Math.floor(Math.random()*tiFontSizes.length)];//fontSizeCombo.ti;
  design.stiFontSize=stiFontSizes[Math.floor(Math.random()*stiFontSizes.length)];//fontSizeCombo.sti;
  design.auFontSize=auFontSizes[Math.floor(Math.random()*auFontSizes.length)];//fontSizeCombo.au;
  var xs=[0.1,0.2,0.3];
  var ys=[0.1,0.2,0.3,0.4,0.5,0.6,0.7];


  design.tiX=Math.floor(boxLayout.ti.X*design.canvasWidth);//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.ti.X*design.canvasWidth);
  const tiYIndex=Math.floor(Math.random()*ys.length);
  design.tiY=Math.floor(boxLayout.ti.Y*design.canvasHeight);//ys[tiYIndex]*design.canvasHeight;//Math.floor(boxLayout.ti.Y*design.canvasHeight);
  design.tiDirection=boxLayout.ti.direction;//'middle';//boxLayout.ti.direction;
  design.tiWidth=Math.floor(boxLayout.ti.W*design.canvasWidth);//design.canvasWidth-design.tiX;//Math.floor(boxLayout.ti.W*design.canvasWidth);
  design.tiHeight=Math.floor(boxLayout.ti.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.ti.H*design.canvasHeight);
  design.tiX+=design.p/2*design.tiWidth;
  design.tiY+=design.p/2*design.tiHeight;
  design.tiWidth-=design.p*design.tiWidth;
  design.tiHeight-=design.p*design.tiHeight;


  /*design.imgX=Math.floor(boxLayout.img.X*design.canvasWidth);
  design.imgY=Math.floor(boxLayout.img.Y*design.canvasHeight);
  design.imgW=Math.floor(boxLayout.img.W*design.canvasWidth);
  design.imgH=Math.floor(boxLayout.img.H*design.canvasHeight);
  design.imgX+=design.p/2*design.imgW;
  design.imgY+=design.p/2*design.imgH;
  design.imgW-=design.p*design.imgW;
  design.imgH-=design.p*design.imgH;
*/

  design.stiX=Math.floor(boxLayout.sti.X*design.canvasWidth);//design.tiX;//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.sti.X*design.canvasWidth);
  ys.splice(tiYIndex,1);
  const stiYIndex=Math.floor(Math.random()*ys.length);
  design.stiY=Math.floor(boxLayout.sti.Y*design.canvasHeight);//ys[stiYIndex]*design.canvasHeight;//Math.floor(boxLayout.sti.Y*design.canvasHeight);
  design.stiDirection=boxLayout.sti.direction;//'middle';//boxLayout.sti.direction;
  design.stiWidth=Math.floor(boxLayout.sti.W*design.canvasWidth);//design.canvasWidth-design.stiX;//Math.floor(boxLayout.sti.W*design.canvasWidth);
  design.stiHeight=Math.floor(boxLayout.sti.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.sti.H*design.canvasHeight);
  design.stiX+=design.p/2*design.stiWidth;
  design.stiY+=design.p/2*design.stiHeight;
  design.stiWidth-=design.p*design.stiWidth;
  design.stiHeight-=design.p*design.stiHeight;

  ys.splice(stiYIndex,1);

  design.auX=Math.floor(boxLayout.au.X*design.canvasWidth);//design.tiX;//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.au.X*design.canvasWidth);
  design.auY=Math.floor(boxLayout.au.Y*design.canvasHeight);//ys[Math.floor(Math.random()*ys.length)]*design.canvasHeight;//Math.floor(boxLayout.au.Y*design.canvasHeight);
  design.auDirection=boxLayout.au.direction;//'middle';//boxLayout.au.direction;
  design.auWidth=Math.floor(boxLayout.au.W*design.canvasWidth);//design.canvasWidth-design.auX;//Math.floor(boxLayout.au.W*design.canvasWidth);
  design.auHeight=Math.floor(boxLayout.au.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.au.H*design.canvasHeight);
  design.auX+=design.p/2*design.auWidth;
  design.auY+=design.p/2*design.auHeight;
  design.auWidth-=design.p*design.auWidth;
  design.auHeight-=design.p*design.auHeight;


  design.label='bad';


 
  if (design.tiCase==='upper') design.ti = title.title.trim().toUpperCase();
  if (design.tiCase==='lower') design.ti = title.title.trim().toLowerCase();

  if (design.stiCase==='upper') design.sti = title.subtitle.trim().toUpperCase();
  if (design.stiCase==='lower') design.sti = title.subtitle.trim().toLowerCase();

  if (design.auCase==='upper') design.au = title.au.trim().toUpperCase();
  if (design.auCase==='lower') design.au = title.au.trim().toLowerCase();

  [design.tiMinWordLength, design.tiMaxWordLength] = getMinMaxLen(design.ti);
  [design.stiMinWordLength, design.stiMaxWordLength] = getMinMaxLen(design.sti);
  [design.auMinWordLength, design.auMaxWordLength] = getMinMaxLen(design.au);

  design.tiNumWords=design.ti.split(' ').length;
  design.stiNumWords=design.sti.split(' ').length;
  design.auNumWords=design.au.split(' ').length;

  design.tiLength=design.ti.length;
  design.stiLength=design.sti.length;
  design.auLength=design.au.length;

  
  //[design.au, design.numAuthors] = getNumAuthors(design.au);
  //console.log(design.au);

  return design;

}

