import React, { useState, useEffect } from "react";

//import { Component } from 'react';
import { loremIpsum, fullname, username } from 'react-lorem-ipsum';
import genDesign from './genDesign.js';
import renderDesign from './renderDesign.js';
import LoaderButton from '../components/LoaderButton';
import VCNav from '../components/VCNav.js';
import jobTitles from './jobTitles.js';
import titlesString from './dataset-1.js';
import quotes from './quotes.js';
import slogans from './slogans.js';
import addresses from './addresses.js';

//class Visualizer extends Component {
export default function Visualizer(props) {

  const products=[
    {
      id:"vc",
      orientation: "landscape",
      numElements: 3,
      heading:"Visiting Cards",
      subHeading: "Designs with name, designation, phone and email.",
      tiPlaceholder: "enter name",
      stiPlaceholder: "enter designation",
      auPlaceholder: "enter phone and email",
      cW: 700,
      cH: 400,
      separator: ", "
    },
    {
      id:"bc",
      orientation: "portrait",
      numElements: 3,
      heading:"Book Covers",
      subHeading: "Designs with title, sub title and author(s).",
      tiPlaceholder: "enter title",
      stiPlaceholder: "enter sub title",
      auPlaceholder: "enter author(s)",
      cW: 528,
      cH: 816,
      separator: " "

    },
    {
      id:"posters",
      orientation: "portrait",
      numElements: 2,
      heading:"Posters",
      subHeading: "Designs with title and sub title.",
      tiPlaceholder: "enter poster title",
      //stiPlaceholder: "enter poster sub title",
      auPlaceholder: "enter text",

      cW: 420,//1584,//800,//420,
      cH: 594,//396,//450,//594,
      separator: " "

    },
    {
      id:"signage",
      orientation: "landscape",
      numElements: 2,
      heading:"Signage",
      subHeading: "Designs with brand or company name and slogan.",
      tiPlaceholder: "enter brand or company name",
      stiPlaceholder: "enter slogan",
      cW: 600,
      cH: 400,
      separator: " "

    },
    {
      id:"labels",
      orientation: "landscape",
      numElements: 2,
      heading:"Labels",
      subHeading: "Designs with name and address.",
      tiPlaceholder: "enter brand or company name",
      auPlaceholder: "enter address",
      cW: 789,//263
      cH: 300,//100
      separator: " "

    }      
  ];

  const [product, setProduct]=useState(0);

  const [predictions, setPredictions]=useState([]);
  const [isLoading, setIsLoading]=useState(false);
  const [title, setTitle]=useState('');
  const [subtitle, setSubTitle]=useState('');
  const [au, setAu]=useState('');
  const [tryExample, setTryExample]=useState(true);


function onFieldChange(event) {
  // for a regular input field, read field name and value from the event
  const fieldName = event.target.name;
  const fieldValue = event.target.value;
  if (fieldName==='title') {
    setTitle(fieldValue);
  } else if (fieldName==='subtitle') {
    setSubTitle(fieldValue);
  } else {
    setAu(fieldValue);
  }
  //this.setState({[fieldName]: fieldValue});
}
  function validateForm() {

    const val= (title.length> 0 
	    && subtitle.length>0 
	    //&& au.length>0
      );
	  
      return val;

  }


  async function generateAgain (event)  {
    event.preventDefault();
    setIsLoading(true);
    await generate(false);
    setIsLoading(false);
  }



async function predict2 (data) {

  const res = await fetch('https://vfioslnq17.execute-api.us-east-1.amazonaws.com/dev/vcmagic', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });

  const body = await res.json();
  return body;
}

useEffect( async () => {
  const pathname=window.location.pathname;
  //console.log(pathname);
  if (pathname === '/vc') {
    setProduct(0);
  } else if (pathname === '/bc') {
    setProduct(1);
  } else if (pathname === '/posters') {
    setProduct(2);
  } else if (pathname === '/signage'){
    setProduct(3);
  } else {
    setProduct(0);
  }

},[]);

useEffect( async () => {

  setIsLoading(true);
  await generate(false);
  setTitle('');
  setSubTitle('');
  setAu('');
  setIsLoading(false);
},[product]);

function visualize(predictions, separator) {

var localDataArray=[], designsDiv, background={};

var preds=predictions;

designsDiv=document.getElementById('designs1');

while (designsDiv.lastChild) {
  designsDiv.removeChild(designsDiv.lastChild);
}


for (let i = 0; i < preds.length; i++) {
  var divEl1 = document.createElement('div');
  divEl1.id='design'+i;


  divEl1.addEventListener("mouseenter", (e) => {
    const index=e.target.id.substr(6,e.currentTarget.id.length-6);
    var btnDiv=document.getElementById('btnDiv'+index);
    btnDiv.className="position-absolute bottom-0 start-50 translate-middle-x";
  });

  divEl1.addEventListener("mouseleave", (e) => {
    const index=e.target.id.substr(6,e.currentTarget.id.length-6);
    var btnDiv=document.getElementById('btnDiv'+index);
    btnDiv.className="d-none";
  });  

  divEl1.className="p-3 col-md-6 col-lg-3 col-sm-12 border-0 mx-0";

  var divEl2 = document.createElement('div');
  divEl2.className="bg-dark card";
  divEl2.style=""
  divEl2.id='design2'+i;
  divEl1.appendChild(divEl2);
  designsDiv.appendChild(divEl1);

  background=renderDesign(preds[i], divEl2, i,false, separator);


  var btnDiv=document.createElement('div');
  btnDiv.id='btnDiv'+i;

  btnDiv.className="d-none";

  var btnEl1=document.createElement('btn');
  btnEl1.className="btn btn-sm btn-secondary";
  btnEl1.innerHTML="Download ";

  var iconEl1=document.createElement('i');
  iconEl1.className="bi-box-arrow-down";

  btnEl1.appendChild(iconEl1);

  btnEl1.id="btnJPG"+i;
  btnEl1.addEventListener("click", (e) => { downloadCanvasAsImage(e, 'image/jpeg')});


  btnDiv.appendChild(btnEl1);
  divEl2.appendChild(btnDiv);

}

}

async function generate() {

var localDataArray=[];
const cW=products[product].cW;
const cH=products[product].cH;
const orientation=products[product].orientation;
const numElements=products[product].numElements;
const separator=products[product].separator;

var ti={};//vcD[Math.floor(Math.random() * vcD.length)];
if (title.length > 0 && !tryExample) {
  ti.title=title;
  ti.au=au;
  ti.subtitle=subtitle;
} else {
  //console.log(product);
    if (product==0) {
      ti.title=fullname();
      ti.au='1111122222, '+username()+'@email.com';
      ti.subtitle=jobTitles[Math.floor(Math.random()*jobTitles.length)];
    } else if (product==1) {
      var titles2=JSON.parse(titlesString);
      var titles2=titles2.filter((t) => t.title.length < 1000 && t.title.length > 0 && t.subtitle.length > 0 && t.authors.length > 0);// && t.title.length < 61);
      ti=titles2[Math.floor(Math.random() * titles2.length)];
      ti.au=ti.authors;
    }
    else if (product==2) {
      var titles2=quotes.filter((q) => q.quote.length < 50);
      var title2=titles2[Math.floor(Math.random() * titles2.length)];
      if (title2.author.substring(title2.author.length-1,title2.length)==',') {
        ti.au=title2.author.substring(0,title2.author.length-1);
      } else ti.au=title2.author;

      ti.title=title2.quote;
      ti.subtitle='';
      //console.log(ti);
      //ti.title=loremIpsum({random: true, startWithLoremIpsum: false, p:1, avgSentencesPerParagraph:1, avgWordsPerSentence: Math.random()*2+2})[0].slice(0, -1);
      //ti.subtitle=loremIpsum({random: true, startWithLoremIpsum: false, p:1, avgSentencesPerParagraph:1, avgWordsPerSentence: Math.random()*5+3})[0].slice(0, -1);
      //ti.au=loremIpsum({random: true, startWithLoremIpsum: false, p:1, avgSentencesPerParagraph:1, avgWordsPerSentence: Math.random()*25+10})[0].slice(0, -1);

    }
    else if (product==3) {
      var slogans2=slogans.filter((t) => t.Company.length > 0 && t.Slogan.length > 0);// && t.title.length < 61);
      var title2=slogans2[Math.floor(Math.random() * slogans2.length)];
      ti.title=title2.Company;
      ti.subtitle=title2.Slogan;
      ti.au='';//loremIpsum({random: true, startWithLoremIpsum: false, p:1, avgSentencesPerParagraph:1, avgWordsPerSentence: Math.random()*15+5})[0].slice(0, -1);
    } else {
      var title2=addresses[Math.floor(Math.random() * addresses.length)];

      ti.title=fullname();
      ti.au=title2.address+', '+title2.city+', '+title2.state+', '+title2.zip;
      ti.subtitle='';

    }
}

var designsTempDiv=document.getElementById('designs2');

for (let i=0; i < 50 ; i++) {
  //console.log(cW,cH,orientation, numElements);
	var design=genDesign(ti,cW,cH,orientation,numElements);//, tiFontSizeIndex, stiFontSizeIndex, auFontSizeIndex);
  design.tiNumLines=0;
  design.tiMaxLineWidth=0;
  design.auNumLines=0;
  design.auMaxLineWidth=0;
  design.auNumLines=0;
  design.auMaxLineWidth=0;
  var output=await renderDesign(design, designsTempDiv, i);
	localDataArray.push(output);
 }

 while (designsTempDiv.lastChild) {
  designsTempDiv.removeChild(designsTempDiv.lastChild);
}

        var response= await predict2(localDataArray);
        //var json=JSON.parse(response.body);
        var json=response.body.split(',');

  const thresh=0.5;
  for (var e = 0; e < json.length; e++) { 
	  localDataArray[e].predictedLabel=(json[e] > thresh ? 1: 0); 
	  localDataArray[e].score=json[e]; 
  }
  
  
  localDataArray.sort((a,b) => b.score - a.score); // b - a for reverse sort
        var filtered = localDataArray.filter(o=> o.predictedLabel ==1 && o.tiMaxLineWidth <= o.tiWidth  && o.tiFontSize*o.tiNumLines <= o.tiHeight);
        //const grouped = groupBy(filtered, pred => pred.boxLayoutId);


	/*let selected=[];
	grouped.forEach((_value, key) => {
          const oneGroup=_value;
	  selected.push(oneGroup[0]);//Math.floor(Math.random()*oneGroup.length)]);
	})*/

//console.log(filtered);
//filtered=filtered.slice(0,12);
	  setPredictions(filtered);	
    visualize(filtered, separator);//fontCombo);


}


function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function downloadCanvasAsImage(e,type) {

  const index=e.currentTarget.id.substr(6,e.currentTarget.id.length-6);
  let canvasImage = document.getElementById('designCanvas'+index).toDataURL(type);
    
    // this can be used to download any image from webpage to local disk
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = 'yourdesign';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove();
      };
      xhr.open('GET', canvasImage); // This is to download the canvas Image
      xhr.send();
}


return (

  <div className="1Login bg-dark text-white">
     <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom border-dark">
        <div className="container-fluid">
          <a className="navbar-brand h1 mb-0" href="/">{process.env.REACT_APP_PRODUCT === 'kavad' ? 'LineCap' : 'LineCap' }</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" href="#" onClick={(e) => setProduct(0)}>Visiting Cards</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" onClick={(e) => setProduct(1)}>Book Covers</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" onClick={(e) => setProduct(2)}>Posters</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" onClick={(e) => setProduct(3)}>Signage</a>
        </li>      
        <li className="nav-item">
          <a className="nav-link" href="#" onClick={(e) => setProduct(4)}>Labels</a>
        </li> 
      </ul>
    </div>
        </div>
      </nav>
    <div className="offset-md-0 col-md-12 col-sm-12 p-lg-5 p-md-3 p-sm-1">
 	  <div className="card-body my-3 col-12 col-lg-6 1col-offset-0 1container offset-lg-0">
      <h1 className="h1 1card-title">{products[product].heading}</h1>
      <p className="1card-subtitle text-white">{products[product].subHeading}</p>
      
      <div className="1card-body my-4">
        <ul className="nav nav-tabs bg-dark">
          <li className="nav-item">
            <a className={tryExample ? "nav-link active" : "nav-link"} aria-current="page" href="#" onClick={(e) => setTryExample(true)}>Try an Example</a>
          </li>
          <li className="nav-item">
            <a className={!tryExample ? "nav-link active" : "nav-link"} href="#" onClick={(e) => setTryExample(false)}>Try your own</a>
          </li>
        </ul>


        {!tryExample && <div className="col-lg-8 col-md-10 col-sm-12">
        {products[product].tiPlaceholder && <div className="card-body">
            <input className="form-control"
              autoFocus
	            required
              value={title}
              type="text"
              placeholder={products[product].tiPlaceholder}
              name="title"
	            onChange={onFieldChange}
            />
	        </div>}
          {products[product].stiPlaceholder && <div className="card-body">
            <input className="form-control"
              value={subtitle}
              type="text"
	            required
              placeholder={products[product].stiPlaceholder}
              name="subtitle"
              onChange={onFieldChange}
            />
          </div>}
          {products[product].auPlaceholder && <div className="card-body">
            <input className="form-control"
              value={au}
              type="text"
	            required
              placeholder={products[product].auPlaceholder}
              name="au"
              onChange={onFieldChange}
             />
           </div>}
        </div>}
        
        <LoaderButton disabled={!validateForm() && !tryExample} isLoading={isLoading} className="mt-3 btn btn-outline-light 1text-white" onClick={generateAgain}>
          Generate designs
        </LoaderButton>

      </div>
    </div>

    {isLoading ? <div className="card-body p-3"><p className="1text-center card-text">loading...</p></div> : 
	    predictions.length < 1 ? <div className="p-3 col-4 offset-4 card-body"><p className="text-center card-text">Sorry, we don't have designs for this text!</p></div> : ''}


    <div className="col-12 d-flex d-none justify-content-left flex-wrap" id="designs2" style={{display: "none"}}></div>
     <div className="col-12 d-flex justify-content-left flex-wrap bg-dark text-white p-0" id="designs1"></div>


  </div>
  <footer className="m-3 py-3 text-muted border-top1 bg-dark text-white">
    {process.env.REACT_APP_PRODUCT === 'kavad' ? <p> &copy; LineCap 2024</p> : <p> &copy; LineCap 2024</p>}
  </footer>
</div>


);}
