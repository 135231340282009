var slogans=[
  {
    "Company": "Costa Coffee",
    "Slogan": "For coffee lovers."
  },
  {
    "Company": "Evian",
    "Slogan": "Evian. Live young."
  },
  {
    "Company": "Dasani",
    "Slogan": "Designed to make a difference."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "TÃ­o Pepe",
    "Slogan": "Good food tastes better after a glass of Tio Pepe"
  },
  {
    "Company": "Tetley's Brewery",
    "Slogan": "Don't Do Things By Halves."
  },
  {
    "Company": "Batemans Brewery",
    "Slogan": "Good Honest Ales"
  },
  {
    "Company": "Jones Soda",
    "Slogan": "Run with the little guyâ¦ create some change."
  },
  {
    "Company": "Grapette",
    "Slogan": "Thirsty or Not."
  },
  {
    "Company": "Jif (lemon juice)",
    "Slogan": "juice in a jiffy."
  },
  {
    "Company": "Royal Tru",
    "Slogan": "Bring out the crazy!"
  },
  {
    "Company": "Oronamin",
    "Slogan": "Genki hatsuratsu."
  },
  {
    "Company": "Culligan",
    "Slogan": "Hey Culligan!"
  },
  {
    "Company": "Belvedere Vodka",
    "Slogan": "Belvedere Always Goes Down Smoothly."
  },
  {
    "Company": "Wodka Gorbatschow",
    "Slogan": "The Pure Spirit of Vodka."
  },
  {
    "Company": "White Horse (whisky)",
    "Slogan": "you can take a white horse anywhere."
  },
  {
    "Company": "Grand Old Parr",
    "Slogan": "Make friends with Old Parr."
  },
  {
    "Company": "Clan Gregor",
    "Slogan": "Royal is my race."
  },
  {
    "Company": "Finlandia (vodka)",
    "Slogan": "Vodka from a Purer Place."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Beam Suntory",
    "Slogan": "For relaxing times, make it Suntory time."
  },
  {
    "Company": "Live+",
    "Slogan": "Sharpen Up!"
  },
  {
    "Company": "Pussy (energy drink)",
    "Slogan": "100% natural."
  },
  {
    "Company": "Tango (drink)",
    "Slogan": "The Whole Fruit."
  },
  {
    "Company": "Tizer",
    "Slogan": "The Great British Pop."
  },
  {
    "Company": "Dr. Enuf",
    "Slogan": "Enuf is Enough!"
  },
  {
    "Company": "ToniCol",
    "Slogan": "the different soft drink."
  },
  {
    "Company": "Campa Cola",
    "Slogan": "The Great Indian Taste."
  },
  {
    "Company": "Mezzo Mix",
    "Slogan": "Cola Kisses orange."
  },
  {
    "Company": "Bluna",
    "Slogan": "How Bluna are you?"
  },
  {
    "Company": "Bionade",
    "Slogan": "The official beverage of a better world."
  },
  {
    "Company": "Oasis (drink)",
    "Slogan": "Be Fruit."
  },
  {
    "Company": "Slice (drink)",
    "Slogan": "It's orange, only twisted."
  },
  {
    "Company": "Rondo (soft drink)",
    "Slogan": "The Thirst Crusher!"
  },
  {
    "Company": "OK Soda",
    "Slogan": "Everything is going to be OK."
  },
  {
    "Company": "Jolt Cola",
    "Slogan": "All the sugar, twice the caffeine!"
  },
  {
    "Company": "Crystal Pepsi",
    "Slogan": "You've never seen a taste like this."
  },
  {
    "Company": "Robinsons (drink)",
    "Slogan": "Real Fruit in Every Drop."
  },
  {
    "Company": "Yazoo (drink)",
    "Slogan": "Milk shaken up."
  },
  {
    "Company": "Bubble Up",
    "Slogan": "kiss of lemon, kiss of lime."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Mecca-Cola",
    "Slogan": "Shake your Conscience."
  },
  {
    "Company": "SodaStream",
    "Slogan": "Get busy with the fizzy."
  },
  {
    "Company": "Monster Energy",
    "Slogan": "Unleash the Beast."
  },
  {
    "Company": "Dr. Brown's",
    "Slogan": "Taste of the Town."
  },
  {
    "Company": "Cheerwine",
    "Slogan": "Uniquely Southern."
  },
  {
    "Company": "Vernors",
    "Slogan": "Deliciously Different."
  },
  {
    "Company": "Gini (soft drink)",
    "Slogan": "The hottest of cold drinks."
  },
  {
    "Company": "Moxie",
    "Slogan": "Just Make It Moxie for Mine."
  },
  {
    "Company": "Mello Yello",
    "Slogan": "Make The Mello Yello Move."
  },
  {
    "Company": "Limca",
    "Slogan": "It's veri veri Lime & Lemoni."
  },
  {
    "Company": "Lilt",
    "Slogan": "the totally tropical taste."
  },
  {
    "Company": "Inca Kola",
    "Slogan": "Let's go for more."
  },
  {
    "Company": "Fresca",
    "Slogan": "Nothing tastes like Fresca."
  },
  {
    "Company": "Barq's",
    "Slogan": "Barq's has bite!"
  },
  {
    "Company": "Pepsi Zero Sugar",
    "Slogan": "Get Buckets."
  },
  {
    "Company": "Horlicks",
    "Slogan": "the food drink of the night."
  },
  {
    "Company": "Woodroofe",
    "Slogan": "Still the best lemonade made."
  },
  {
    "Company": "Konig's Westphalian Gin",
    "Slogan": "The only perfect gin."
  },
  {
    "Company": "Evoca Cola",
    "Slogan": "The original Black Seed Cola."
  },
  {
    "Company": "Jolly Cola",
    "Slogan": "Free your taste."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Sunkist (soft drink)",
    "Slogan": "Good Vibrations."
  },
  {
    "Company": "Pluto Water",
    "Slogan": "When Nature Won't, PLUTO Will."
  },
  {
    "Company": "Shasta (soft drink)",
    "Slogan": "It hasta be Shasta!"
  },
  {
    "Company": "Taut",
    "Slogan": "The first clean sports drink."
  },
  {
    "Company": "Codorniu",
    "Slogan": "The Culture of Pleasure."
  },
  {
    "Company": "Jacob's Creek",
    "Slogan": "Say when."
  },
  {
    "Company": "Wolf Blass",
    "Slogan": "Australian wine at its peak."
  },
  {
    "Company": "Sutter Home Winery",
    "Slogan": "Taste the Commitment."
  },
  {
    "Company": "Blavod",
    "Slogan": "Outrageously Smooth."
  },
  {
    "Company": "Patron Tequila",
    "Slogan": "Simply perfect."
  },
  {
    "Company": "Malibu (rum)",
    "Slogan": "Seriously easy going."
  },
  {
    "Company": "Tanqueray",
    "Slogan": "Ready to Tanqueray?"
  },
  {
    "Company": "Dreher Breweries",
    "Slogan": "Itâs whatâs inside that truly counts."
  },
  {
    "Company": "Staropramen Brewery",
    "Slogan": "Get A Taste Of Prague."
  },
  {
    "Company": "Adnams",
    "Slogan": "Southwold, by the pint."
  },
  {
    "Company": "Carlton Cold",
    "Slogan": "Nothingâs as fresh as a Coldie."
  },
  {
    "Company": "Schaefer Beer",
    "Slogan": "Americaâs Oldest Lager Beer."
  },
  {
    "Company": "Minute Maid",
    "Slogan": "Loaded with Taste."
  },
  {
    "Company": "Harp Lager",
    "Slogan": "Look on the Harp side."
  },
  {
    "Company": "Poland Spring",
    "Slogan": "Born Better."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Appy Fizz",
    "Slogan": "Cool Drink to Hang Around With."
  },
  {
    "Company": "Joseph Schlitz Brewing Company",
    "Slogan": "The beer that made Milwaukee famous."
  },
  {
    "Company": "Frooti",
    "Slogan": "Juice Up your Life."
  },
  {
    "Company": "Cointreau",
    "Slogan": "Be cointreauversial."
  },
  {
    "Company": "Disaronno",
    "Slogan": "Taste the seduction"
  },
  {
    "Company": "Norrlands Guld",
    "Slogan": "Be yourself for a while"
  },
  {
    "Company": "Mackeson Beer",
    "Slogan": "king of the night."
  },
  {
    "Company": "Tuborg",
    "Slogan": "Beer YOURSELF"
  },
  {
    "Company": "Cafe Britt",
    "Slogan": "From our plantation to your cup!"
  },
  {
    "Company": "Boost (drink)",
    "Slogan": "Refresh yourself."
  },
  {
    "Company": "Shloer",
    "Slogan": "Get your sparkle!"
  },
  {
    "Company": "Grey Goose (vodka)",
    "Slogan": "The worldâs best tasting vodka."
  },
  {
    "Company": "Ballantine's",
    "Slogan": "Play in moderation."
  },
  {
    "Company": "Jose Cuervo",
    "Slogan": "Donât let go."
  },
  {
    "Company": "Black Velvet (whisky)",
    "Slogan": "Feel the Velvet"
  },
  {
    "Company": "Houghton Wines",
    "Slogan": "Perfect for when friends drop in."
  },
  {
    "Company": "Lindeman's",
    "Slogan": "You make me smile."
  },
  {
    "Company": "United Breweries",
    "Slogan": "The King of Good Times."
  },
  {
    "Company": "Caribou Coffee",
    "Slogan": "Stay awake for it"
  },
  {
    "Company": "RicorÃ©",
    "Slogan": "Friend RicorÃ©."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Horn & Hardart",
    "Slogan": "Less Work for Mother."
  },
  {
    "Company": "High Point (coffee)",
    "Slogan": "Decaffeinate the ones you love."
  },
  {
    "Company": "Guglielmo coffee",
    "Slogan": "the coffee that hits the spot."
  },
  {
    "Company": "Postum",
    "Slogan": "There's a Reason."
  },
  {
    "Company": "MiO",
    "Slogan": "Flip it. Tip it. Sip it!"
  },
  {
    "Company": "Gold Spot",
    "Slogan": "The Zing Thing."
  },
  {
    "Company": "Frugo",
    "Slogan": "Let's Frugo."
  },
  {
    "Company": "Double Diamond Beer",
    "Slogan": "Iâm only here for the beer."
  },
  {
    "Company": "Gilbey's gin",
    "Slogan": "Taste the smoothest gin today."
  },
  {
    "Company": "Go Fast!",
    "Slogan": "Pure Energy."
  },
  {
    "Company": "Haig (whisky)",
    "Slogan": "Donât Be Vague, Ask For A Haig."
  },
  {
    "Company": "A Twosome Place",
    "Slogan": "A cup of coffee, TWO of us"
  },
  {
    "Company": "Tiger Energy Drink",
    "Slogan": "Power is back!"
  },
  {
    "Company": "Um Bongo",
    "Slogan": "The great flavour of the jungle"
  },
  {
    "Company": "Super Bock",
    "Slogan": "The beer that goes beyond your expectations"
  },
  {
    "Company": "Bell's whisky",
    "Slogan": "Afore ye go."
  },
  {
    "Company": "Irn-Bru",
    "Slogan": "Gets you through."
  },
  {
    "Company": "Apollinaris (water)",
    "Slogan": "âThe Queen of Table Waters."
  },
  {
    "Company": "Licher Brewery",
    "Slogan": "From the Heart of Nature"
  },
  {
    "Company": "Bitburger Brewery",
    "Slogan": "Bitte ein Bit."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Banania",
    "Slogan": "the y'a bon buddy."
  },
  {
    "Company": "Nabob (coffee)",
    "Slogan": "Better beans, better coffee, better planet."
  },
  {
    "Company": "Carling Black Label",
    "Slogan": "Hey Mabel, Black Label!"
  },
  {
    "Company": "Solo",
    "Slogan": "Game On."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Manischewitz",
    "Slogan": "Man-O-Manischewitz What a Wine!"
  },
  {
    "Company": "National Bohemian",
    "Slogan": "What a beer!"
  },
  {
    "Company": "Christian Schmidt Brewing Company",
    "Slogan": "One Beautiful Beer."
  },
  {
    "Company": "Camp Coffee",
    "Slogan": "Ready aye ready."
  },
  {
    "Company": "Luzianne",
    "Slogan": "Take a fresh look at your iced tea."
  },
  {
    "Company": "5-hour Energy",
    "Slogan": "Hours and hours of energy."
  },
  {
    "Company": "Full Throttle",
    "Slogan": "Feel the energy at work."
  },
  {
    "Company": "V Energy",
    "Slogan": "It all starts with V."
  },
  {
    "Company": "Relentless (drink)",
    "Slogan": "Be Relentless."
  },
  {
    "Company": "NOS (drink)",
    "Slogan": "You only live NOS."
  },
  {
    "Company": "Vault (drink)",
    "Slogan": "Get it done, and then some."
  },
  {
    "Company": "Hype Energy Drinks",
    "Slogan": "Feel the Hype."
  },
  {
    "Company": "Goodricke tea",
    "Slogan": "The tea people."
  },
  {
    "Company": "BOH tea",
    "Slogan": "Share the Ummph!"
  },
  {
    "Company": "Tavalon Tea",
    "Slogan": "The future of tea."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Diet Coke",
    "Slogan": "Light it up!"
  },
  {
    "Company": "E & J Gallo Winery",
    "Slogan": "The new art of wine."
  },
  {
    "Company": "Greene King",
    "Slogan": "Beer to Dine For."
  },
  {
    "Company": "Tapal Tea",
    "Slogan": "Differ tastefully."
  },
  {
    "Company": "Thums Up",
    "Slogan": "Taste The Thunder."
  },
  {
    "Company": "Tropicana",
    "Slogan": "Straight from the fruit."
  },
  {
    "Company": "V8 Vegetable Juice",
    "Slogan": "Drink Your Vegetables."
  },
  {
    "Company": "Vittoria Coffee",
    "Slogan": "Where our expertise is still a family traditi"
  },
  {
    "Company": "Cutty Sark (whisky)",
    "Slogan": "Live a Cutty above."
  },
  {
    "Company": "Dewar's",
    "Slogan": "It never varies."
  },
  {
    "Company": "Chivas Regal",
    "Slogan": "This is Chivas Life."
  },
  {
    "Company": "Appleton Jamaica Rum",
    "Slogan": "Raise your Rum!"
  },
  {
    "Company": "Kahlua",
    "Slogan": "Awaken the spirit."
  },
  {
    "Company": "Courage Brewery",
    "Slogan": "Take Courage."
  },
  {
    "Company": "Kronenbourg",
    "Slogan": "Sit. Savour. 1664."
  },
  {
    "Company": "Bass Brewery",
    "Slogan": "A little bit of better."
  },
  {
    "Company": "Labatt Brewing Company",
    "Slogan": "Good things brewing."
  },
  {
    "Company": "Kokanee beer",
    "Slogan": "Straight from the Kootenays."
  },
  {
    "Company": "Old Milwaukee",
    "Slogan": "Taste as great as it's name."
  },
  {
    "Company": "Foster's Lager",
    "Slogan": "Australian for beer."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Amstel Brewery",
    "Slogan": "Taste life. Pure Filtered."
  },
  {
    "Company": "Guinness",
    "Slogan": "Guinness is Good for You."
  },
  {
    "Company": "Seattle's Best Coffee",
    "Slogan": "Great coffee everywhere."
  },
  {
    "Company": "Saxbys Coffee",
    "Slogan": "Start your day fresh."
  },
  {
    "Company": "Tchibo",
    "Slogan": "Passionate about coffee."
  },
  {
    "Company": "Brim coffee",
    "Slogan": "Fill it to the rim with Brim."
  },
  {
    "Company": "Sanka coffee",
    "Slogan": "One fiddler you won't have to pay."
  },
  {
    "Company": "Bombay Sapphire",
    "Slogan": "Pour something priceless."
  },
  {
    "Company": "Hendrick's Gin",
    "Slogan": "A most unusual gin."
  },
  {
    "Company": "Gordon's Gin",
    "Slogan": "Re-mix yourself."
  },
  {
    "Company": "Beefeater Gin",
    "Slogan": "Make it a double."
  },
  {
    "Company": "Baileys Irish Cream",
    "Slogan": "The Pleasure Society."
  },
  {
    "Company": "Captain Morgan",
    "Slogan": "Enjoy the contradiction."
  },
  {
    "Company": "Sauza Tequila",
    "Slogan": "Fun is calling."
  },
  {
    "Company": "Wyborowa Vodka",
    "Slogan": "Exquisite wodka."
  },
  {
    "Company": "Canadian Club",
    "Slogan": "âNo ordinary Whisky."
  },
  {
    "Company": "Arbor Mist",
    "Slogan": "Just what you've been looking for."
  },
  {
    "Company": "Aqua Pura",
    "Slogan": "Be the best you can be."
  },
  {
    "Company": "Bisleri",
    "Slogan": "The sweet taste of purity."
  },
  {
    "Company": "Evan Williams",
    "Slogan": "Aged longer to taste smoother."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Jamba Juice",
    "Slogan": "Good tidings we blend."
  },
  {
    "Company": "Lyons tea",
    "Slogan": "Puts the talk into tea."
  },
  {
    "Company": "Brooke Bond",
    "Slogan": "Cheers your senses."
  },
  {
    "Company": "Bushells",
    "Slogan": "Our tea, our way."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Sierra Mist",
    "Slogan": "It's that refreshing."
  },
  {
    "Company": "Sprite",
    "Slogan": "Obey Your Thirst."
  },
  {
    "Company": "Kenco",
    "Slogan": "Growing great coffee and more."
  },
  {
    "Company": "Lavazza",
    "Slogan": "Good karma. Great coffee."
  },
  {
    "Company": "Moccona coffee",
    "Slogan": "Wake up to something special."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Melitta",
    "Slogan": "Coffee indulgence."
  },
  {
    "Company": "Eight O'Clock Coffee",
    "Slogan": "For Those Who Put Coffee First."
  },
  {
    "Company": "Douwe Egberts",
    "Slogan": "No ordinary jar. No ordinary coffee."
  },
  {
    "Company": "Gevalia coffee",
    "Slogan": "Let's coffee."
  },
  {
    "Company": "Folgers",
    "Slogan": "The best part of wakin' up."
  },
  {
    "Company": "Gold Peak Tea",
    "Slogan": "Home brewed taste."
  },
  {
    "Company": "Honest Tea",
    "Slogan": "Refreshingly Honest."
  },
  {
    "Company": "Lucozade",
    "Slogan": "Lucozade aids recovery."
  },
  {
    "Company": "Powerade",
    "Slogan": "The power of love."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Yogi Tea",
    "Slogan": "How good can you feel?"
  },
  {
    "Company": "Celestial Seasonings",
    "Slogan": "Make any moment Celestial."
  },
  {
    "Company": "Barry's Tea",
    "Slogan": "Every day should have its golden moments."
  },
  {
    "Company": "Clipper tea",
    "Slogan": "Britain's favourite green tea."
  },
  {
    "Company": "Dilmah",
    "Slogan": "The finest tea on earth."
  },
  {
    "Company": "Kericho Gold",
    "Slogan": "A Matter of Good Taste"
  },
  {
    "Company": "PG Tips tea",
    "Slogan": "Put the kettle on."
  },
  {
    "Company": "Punjana tea",
    "Slogan": "Everyone's cup of tea."
  },
  {
    "Company": "Yorkshire Tea",
    "Slogan": "Let's have a proper brew."
  },
  {
    "Company": "Red Rose Tea",
    "Slogan": "Red Rose Tea is good tea."
  },
  {
    "Company": "Twinings tea",
    "Slogan": "Gets you back to you."
  },
  {
    "Company": "Tazo Tea",
    "Slogan": "It begins with tea."
  },
  {
    "Company": "Zealong tea",
    "Slogan": "100% pure New Zealand tea."
  },
  {
    "Company": "Ten Ren Tea",
    "Slogan": "Ten Ren's. Tea time."
  },
  {
    "Company": "Tetley tea",
    "Slogan": "Make time, make Tetley."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Badoit",
    "Slogan": "The petillant table water."
  },
  {
    "Company": "Vittel",
    "Slogan": "It must be the water."
  },
  {
    "Company": "Perrier",
    "Slogan": "Perrier. The ultimate refreshment."
  },
  {
    "Company": "Donat Mg",
    "Slogan": "More than magnesium."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Naya Waters",
    "Slogan": "Hungry for life. Thirsty for Naya."
  },
  {
    "Company": "S.Pellegrino",
    "Slogan": "S. Pellegrino. Live in Italian."
  },
  {
    "Company": "Volvic",
    "Slogan": "Filled with volcanicity."
  },
  {
    "Company": "Aquafina",
    "Slogan": "Make your body happy."
  },
  {
    "Company": "Don Julio",
    "Slogan": "Know your tequila."
  },
  {
    "Company": "Olmeca Tequila",
    "Slogan": "Switch on the night."
  },
  {
    "Company": "Jameson Irish Whiskey",
    "Slogan": "The spirit of Ireland."
  },
  {
    "Company": "Glenfiddich",
    "Slogan": "Single source of inspiration."
  },
  {
    "Company": "Stolichnaya vodka",
    "Slogan": "Exquisite wodka."
  },
  {
    "Company": "Smirnoff vodka",
    "Slogan": "The greatest name in Vodka."
  },
  {
    "Company": "Tree Top",
    "Slogan": "Nurtured from the start."
  },
  {
    "Company": "Grolsch Brewery",
    "Slogan": "No Oneâs Ever Changed The World By Being Boaring."
  },
  {
    "Company": "Remy Martin",
    "Slogan": "Feel More."
  },
  {
    "Company": "Hennessy",
    "Slogan": "Mix accordingly."
  },
  {
    "Company": "Martell (cognac)",
    "Slogan": "Only a Few Can Tell."
  },
  {
    "Company": "Four Roses",
    "Slogan": "America's most gifted whiskey."
  },
  {
    "Company": "Maker's Mark",
    "Slogan": "Please enjoy it that way."
  },
  {
    "Company": "Jim Beam",
    "Slogan": "Real Friends. Real Bourbon."
  },
  {
    "Company": "Wild Turkey (bourbon)",
    "Slogan": "Too good to keep cooped up."
  },
  {
    "Company": "Rolling Rock",
    "Slogan": "Rolling Rock. Free Flowing."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Pilsner Urquell",
    "Slogan": "The world's first golden beer."
  },
  {
    "Company": "Boddingtons",
    "Slogan": "It's a bit gorgeous."
  },
  {
    "Company": "Beck's",
    "Slogan": "Beck's. Life Beckons."
  },
  {
    "Company": "Sapporo",
    "Slogan": "Senses Never Forget."
  },
  {
    "Company": "Stella Artois",
    "Slogan": "Reassuringly expensive"
  },
  {
    "Company": "Paulaner Brewery",
    "Slogan": "Good, better, Paulaner."
  },
  {
    "Company": "Corona",
    "Slogan": "Miles Away From ordinary."
  },
  {
    "Company": "Red Stripe",
    "Slogan": "It's BEER. Hooray beer!"
  },
  {
    "Company": "Castlemaine XXXX",
    "Slogan": "Australians wouldn't give a XXXX for anything"
  },
  {
    "Company": "Newcastle Brown Ale",
    "Slogan": "The One and Only."
  },
  {
    "Company": "St. Pauli Girl",
    "Slogan": "Germany's Fun-Loving Beer."
  },
  {
    "Company": "Dos Equis",
    "Slogan": "Let your tastes travel."
  },
  {
    "Company": "Bad Frog",
    "Slogan": "The Beer so Good it's Bad."
  },
  {
    "Company": "Molson Canadian",
    "Slogan": "It Starts Here."
  },
  {
    "Company": "A&W Root Beer",
    "Slogan": "That frosty mug sensation."
  },
  {
    "Company": "Coors Brewing Company",
    "Slogan": "It won't slow you down."
  },
  {
    "Company": "Three Olives Vodka",
    "Slogan": "Crisp. Clean. Smooth."
  },
  {
    "Company": "Snapple",
    "Slogan": "Made from the best stuff on Earth."
  },
  {
    "Company": "Schweppes",
    "Slogan": "Schhh! You know who?"
  },
  {
    "Company": "Canadian Mist",
    "Slogan": "Grip it. Tip it. Sip it."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Johnnie Walker",
    "Slogan": "Johnnie Walker. Keep Walking."
  },
  {
    "Company": "Maxwell House",
    "Slogan": "Good to the last drop."
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Miller Beer",
    "Slogan": "If youâve got the time, weâve got the beer."
  },
  {
    "Company": "Juicy Juice",
    "Slogan": "100% juice for 100% kids."
  },
  {
    "Company": "RC cola",
    "Slogan": "Relax, and Enjoy Royal Crown Cola"
  },
  {
    "Company": "Skyy Vodka",
    "Slogan": "Drink dirty martinis, not dirty vodka"
  },
  {
    "Company": "Hawaiian Punch",
    "Slogan": "How about a nice Hawaiian Punch?"
  },
  {
    "Company": "Mountain Dew",
    "Slogan": "Itâll tickle your innards."
  },
  {
    "Company": "Absolut Vodka",
    "Slogan": "ABSOLUT PERFECTION."
  },
  {
    "Company": "Bacardi",
    "Slogan": "Thirsty for life? Drink Responsibly."
  },
  {
    "Company": "Naked Juice",
    "Slogan": "Worth its weight in good."
  },
  {
    "Company": "Pepsi",
    "Slogan": "Delicious. Refreshing. Pepsi."
  },
  {
    "Company": "Carabao",
    "Slogan": "Free your inner Carabao."
  },
  {
    "Company": "Budweiser",
    "Slogan": "The king of beers."
  },
  {
    "Company": "7 Up",
    "Slogan": "The Un-cola."
  },
  {
    "Company": "Carlsberg",
    "Slogan": "Probably the Best Beer in the World."
  },
  {
    "Company": "Typhoo",
    "Slogan": "Typhoo puts the T in Britain."
  },
  {
    "Company": "Dr. Pepper",
    "Slogan": "Whatâs the Worst That Could Happen?"
  },
  {
    "Company": "Red Bull",
    "Slogan": "It Gives You Wiiiings."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Chupa Chups",
    "Slogan": "Smoke Chupa Chups."
  },
  {
    "Company": "Tic Tac",
    "Slogan": "It's not just a mint."
  },
  {
    "Company": "Toblerone",
    "Slogan": "Make time for Toblerone."
  },
  {
    "Company": "Helms Bakery",
    "Slogan": "Daily at Your Door"
  },
  {
    "Company": "Lollicup Coffee & Tea",
    "Slogan": "Add a Little Fun, Mix a Little Flavor"
  },
  {
    "Company": "Baker's Dozen Donuts",
    "Slogan": "Not Just Donuts..."
  },
  {
    "Company": "Dan-D Foods",
    "Slogan": "Fine Foods of the Earth"
  },
  {
    "Company": "Canyon Creek Food Company",
    "Slogan": "Favorite foods made easy."
  },
  {
    "Company": "Wispa",
    "Slogan": "Time Well Mis-spent"
  },
  {
    "Company": "Cadbury Roses",
    "Slogan": "Roses Grow On You."
  },
  {
    "Company": "Picnic (chocolate bar)",
    "Slogan": "Deliciously ugly"
  },
  {
    "Company": "Moro (chocolate bar)",
    "Slogan": "Get more go"
  },
  {
    "Company": "Crunchie",
    "Slogan": "Get that Friday feeling"
  },
  {
    "Company": "Chomp (chocolate bar)",
    "Slogan": "It's a monster chew!"
  },
  {
    "Company": "Trix (cereal)",
    "Slogan": "Trix Are For Kids!"
  },
  {
    "Company": "Time Out (chocolate bar)",
    "Slogan": "âthe wafer break with a layer of Flake."
  },
  {
    "Company": "Violet Crumble",
    "Slogan": "It's the way it shatters that matters."
  },
  {
    "Company": "Cadbury Creme Egg",
    "Slogan": "It's Hunting Season."
  },
  {
    "Company": "Planters",
    "Slogan": "Naturally Remarkable."
  },
  {
    "Company": "Chips Ahoy!",
    "Slogan": "Crazy with chips!"
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Fancy Feast",
    "Slogan": "The best ingredient is love."
  },
  {
    "Company": "Dolmio",
    "Slogan": "Whenâsa your Dolmio day?"
  },
  {
    "Company": "Hula Hoops",
    "Slogan": "Best ever flavour."
  },
  {
    "Company": "5 (gum)",
    "Slogan": "Stimulate Your Senses."
  },
  {
    "Company": "Peperami",
    "Slogan": "All Pork, No Bull."
  },
  {
    "Company": "Colman's",
    "Slogan": "Come on Colman's, light my fire."
  },
  {
    "Company": "Boursin cheese",
    "Slogan": "Some bread, some wine, some Boursin."
  },
  {
    "Company": "Butterfinger",
    "Slogan": "better butter finger."
  },
  {
    "Company": "Boost (chocolate bar)",
    "Slogan": "One Step Ahead."
  },
  {
    "Company": "3 Musketeers",
    "Slogan": "Big on Chocolate!"
  },
  {
    "Company": "Trident (gum)",
    "Slogan": "See what Unfolds."
  },
  {
    "Company": "Cheetos",
    "Slogan": "Dangerously cheesy."
  },
  {
    "Company": "Hostess CupCake",
    "Slogan": "Where's the cream filling?"
  },
  {
    "Company": "Ouma Rusks",
    "Slogan": "Baked on the original farm."
  },
  {
    "Company": "Oreo",
    "Slogan": "Only oreo."
  },
  {
    "Company": "Nik Naks",
    "Slogan": "Eat The Freak."
  },
  {
    "Company": "Ruffles",
    "Slogan": "Ruffles have RRRidges."
  },
  {
    "Company": "Toaster Strudel",
    "Slogan": "Get Zem GÃ¶ing."
  },
  {
    "Company": "Tastykake",
    "Slogan": "The Cake That Made Mother Stop Baking."
  },
  {
    "Company": "Walkers",
    "Slogan": "Fresh Taste Guaranteed."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Branston",
    "Slogan": "Bring out the Branston."
  },
  {
    "Company": "Twisties",
    "Slogan": "Life's pretty straight without..."
  },
  {
    "Company": "Burger Rings",
    "Slogan": "Full on burger flavour."
  },
  {
    "Company": "Start (cereal)",
    "Slogan": "I Want, Now I Can."
  },
  {
    "Company": "Cocoa Krispies",
    "Slogan": "Can you handle the crunch?"
  },
  {
    "Company": "Rice-A-Roni",
    "Slogan": "The San Francisco Treat."
  },
  {
    "Company": "Life Savers",
    "Slogan": "Still only 5 cents."
  },
  {
    "Company": "Golden Crisp",
    "Slogan": "Canât Get Enough of That Golden Crisp."
  },
  {
    "Company": "Alpha Bits",
    "Slogan": "They're ice-cream-a-licious!"
  },
  {
    "Company": "Cheerios",
    "Slogan": "Toasted Whole Grain Oat Cereal."
  },
  {
    "Company": "Kix (cereal)",
    "Slogan": "Kid-tested. Parent-approved."
  },
  {
    "Company": "Grape Nuts",
    "Slogan": "Makes Red Blood!"
  },
  {
    "Company": "Apple Jacks",
    "Slogan": "We eat what we like."
  },
  {
    "Company": "Sugar Puffs",
    "Slogan": "You'll Go Monster-Mad For The Honey."
  },
  {
    "Company": "Yorkie (chocolate bar)",
    "Slogan": "Itâs Not For Girls."
  },
  {
    "Company": "Aero (chocolate)",
    "Slogan": "Have you felt the bubbles melt?"
  },
  {
    "Company": "Tootsie Pop",
    "Slogan": "the center of a Tootsie Pop."
  },
  {
    "Company": "A.1. Sauce",
    "Slogan": "Makes beef sing."
  },
  {
    "Company": "Rolaids",
    "Slogan": "that's how you spell relief."
  },
  {
    "Company": "Jell-O",
    "Slogan": "There's always room for Jell-O."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Prego",
    "Slogan": "It's in there!."
  },
  {
    "Company": "Heinz Baked Beans",
    "Slogan": "Beanz meanz Heinz."
  },
  {
    "Company": "Blue Bonnet",
    "Slogan": "I'm on it like Blue Bonnet!"
  },
  {
    "Company": "Honey Bunches of Oats",
    "Slogan": "It's what's for breakfast."
  },
  {
    "Company": "Birds Eye",
    "Slogan": "Come Home To Birds Eye Country."
  },
  {
    "Company": "Candwich",
    "Slogan": "Quick & Tasty, Ready to Eat."
  },
  {
    "Company": "Casa Sanchez",
    "Slogan": "flavor out of this world."
  },
  {
    "Company": "Francesco Rinaldi",
    "Slogan": "Enjoyed by everyone."
  },
  {
    "Company": "Idaho Spud",
    "Slogan": "The Candy Bar That Makes Idaho Famous."
  },
  {
    "Company": "Meggle",
    "Slogan": "Ich bin ein Gourmeggle."
  },
  {
    "Company": "Quaker",
    "Slogan": "Nothing is better for thee, than me."
  },
  {
    "Company": "Shurfine",
    "Slogan": "You sure save more at a ShurSave store."
  },
  {
    "Company": "Shreddies",
    "Slogan": "Shreddie or not?"
  },
  {
    "Company": "Wagon Wheels",
    "Slogan": "You've got to grin to get it in."
  },
  {
    "Company": "Bisto",
    "Slogan": "Aah, Bisto!"
  },
  {
    "Company": "Twizzlers",
    "Slogan": "Makes mouths happy."
  },
  {
    "Company": "Hillshire Farm",
    "Slogan": "Go Meat!"
  },
  {
    "Company": "Campbell's",
    "Slogan": "M'm! M'm! Good!"
  },
  {
    "Company": "Wheaties",
    "Slogan": "The breakfast of champions."
  },
  {
    "Company": "Frosted Flakes",
    "Slogan": "They're g-r-r-r-eat!"
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Skittles",
    "Slogan": "Taste the rainbow."
  },
  {
    "Company": "Ajinomoto",
    "Slogan": "Eat Well, Live Well."
  },
  {
    "Company": "Mamee Double-Decker",
    "Slogan": "A World of Good Taste."
  },
  {
    "Company": "Freia (chocolate)",
    "Slogan": "A small piece of Norway."
  },
  {
    "Company": "Golden Wonder",
    "Slogan": "You'll never grow old in Golden Wonderland!"
  },
  {
    "Company": "Ferrero-KÃ¼sschen",
    "Slogan": "Give a KÃ¼sschen to your friend!"
  },
  {
    "Company": "Ritter Sport",
    "Slogan": "Quality in a Square."
  },
  {
    "Company": "Haribo",
    "Slogan": "Child and adult is happy."
  },
  {
    "Company": "PÃR Gum",
    "Slogan": "Live PUR."
  },
  {
    "Company": "Chicken Tonight",
    "Slogan": "I feel like Chicken Tonight."
  },
  {
    "Company": "Hobnob biscuit",
    "Slogan": "Chocolate now has Hobnobs underneath."
  },
  {
    "Company": "Homepride",
    "Slogan": "the one with the bag."
  },
  {
    "Company": "Marmite",
    "Slogan": "Love it or hate it."
  },
  {
    "Company": "Tip Top Bakeries",
    "Slogan": "Tip Top's the One."
  },
  {
    "Company": "Chiko Roll",
    "Slogan": "You can't knock the roll."
  },
  {
    "Company": "Now and Later",
    "Slogan": "Share the Square."
  },
  {
    "Company": "Milky Way",
    "Slogan": "Life's Better the Milky Way."
  },
  {
    "Company": "Dippin' Dots",
    "Slogan": "Ice Cream of the Future"
  },
  {
    "Company": "Lunchables",
    "Slogan": "Make fun of lunch!"
  },
  {
    "Company": "Smucker",
    "Slogan": "it has to be good."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Reese's Peanut Butter Cups",
    "Slogan": "Reese's ...perfect"
  },
  {
    "Company": "Bertolli",
    "Slogan": "Passion makes perfect."
  },
  {
    "Company": "Stork (margarine)",
    "Slogan": "The difference is worth talking about."
  },
  {
    "Company": "Flora (margarine)",
    "Slogan": "Better butteryness."
  },
  {
    "Company": "Country Crock",
    "Slogan": "Where good habits are delicious."
  },
  {
    "Company": "Clover (spread)",
    "Slogan": "Made for family."
  },
  {
    "Company": "Meadow Lea",
    "Slogan": "You ought to be congratulated."
  },
  {
    "Company": "Blue Band",
    "Slogan": "Spread every slice!"
  },
  {
    "Company": "Imperial margarine",
    "Slogan": "Fit for a king."
  },
  {
    "Company": "Becel",
    "Slogan": "Love your heart."
  },
  {
    "Company": "Lurpak",
    "Slogan": "Your everyday luxury."
  },
  {
    "Company": "Utterly Butterly",
    "Slogan": "Utterly smitten."
  },
  {
    "Company": "President cheese",
    "Slogan": "Perfectly paired with life."
  },
  {
    "Company": "Primula",
    "Slogan": "Perfect for sharing."
  },
  {
    "Company": "Babybel",
    "Slogan": "100% natural. 100% fun!"
  },
  {
    "Company": "Lea & Perrins",
    "Slogan": "The original and Genuine."
  },
  {
    "Company": "Peters Ice Cream",
    "Slogan": "Music to your mouth."
  },
  {
    "Company": "Weis",
    "Slogan": "The taste on everyone's lips."
  },
  {
    "Company": "Mackie's",
    "Slogan": "Creamy by nature!"
  },
  {
    "Company": "Magnum (ice cream)",
    "Slogan": "For pleasure seekers."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Klondike bar",
    "Slogan": "What would you do for a Klondike bar?"
  },
  {
    "Company": "Cravendale",
    "Slogan": "The cows want it back"
  },
  {
    "Company": "After Eight",
    "Slogan": "See you After Eight."
  },
  {
    "Company": "Terry's",
    "Slogan": "Round. But not round for long."
  },
  {
    "Company": "Little Debbie",
    "Slogan": "Unwrap a smile."
  },
  {
    "Company": "Ryvita",
    "Slogan": "Britton loves Ryvita."
  },
  {
    "Company": "Beech-Nut",
    "Slogan": "Small Is Big Here."
  },
  {
    "Company": "Carvel (franchise)",
    "Slogan": "America's freshest ice cream."
  },
  {
    "Company": "Doublemint gum",
    "Slogan": "Donât Forget The Fruit Gums."
  },
  {
    "Company": "Kellogg's",
    "Slogan": "The simpler the better."
  },
  {
    "Company": "Lucky Charms",
    "Slogan": "Theyâre magically delicious."
  },
  {
    "Company": "Mentos",
    "Slogan": "Roses Grow on You."
  },
  {
    "Company": "Starburst(Opal Fruits)",
    "Slogan": "Unexplainably Juicy."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "Twix",
    "Slogan": "Itâs all in the mix."
  },
  {
    "Company": "Swensen's",
    "Slogan": "Good as father used to make."
  },
  {
    "Company": "Marble Slab Creamery",
    "Slogan": "Originality in every scoop."
  },
  {
    "Company": "Graeter's",
    "Slogan": "Irresistible Graeter's."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Baskin-Robbins",
    "Slogan": "More flavors. More fun."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Ore-Ida",
    "Slogan": "Easy Open, Easy Pour Bag!"
  },
  {
    "Company": "Kraft Foods",
    "Slogan": "A little taste of heaven."
  },
  {
    "Company": "Kelly's of Cornwall",
    "Slogan": "Cornish by nature."
  },
  {
    "Company": "HB ice cream",
    "Slogan": "One of life's simple pleasures."
  },
  {
    "Company": "Vadilal",
    "Slogan": "Nothing but goodness."
  },
  {
    "Company": "Tip Top",
    "Slogan": "Tip Top. Real ice creamier."
  },
  {
    "Company": "Hunt's",
    "Slogan": "Only the best tomatoes grow up to be Hunt's."
  },
  {
    "Company": "Hellmann's",
    "Slogan": "Bring out the best."
  },
  {
    "Company": "Bega Cheese",
    "Slogan": "Better buy Bega."
  },
  {
    "Company": "Coon cheese",
    "Slogan": "The choice of COONoisseurs."
  },
  {
    "Company": "Jarlsberg cheese",
    "Slogan": "Only Jarlsberg is Jarlsberg."
  },
  {
    "Company": "Dairylea (cheese)",
    "Slogan": "Herds of Dairylea goodness."
  },
  {
    "Company": "Wyke Farms",
    "Slogan": "Over 100 years of family farming."
  },
  {
    "Company": "Pilgrims Choice",
    "Slogan": "Good choice pilgrim."
  },
  {
    "Company": "Wall's",
    "Slogan": "Share happy."
  },
  {
    "Company": "Blue Bell Creameries",
    "Slogan": "The best ice cream in the country."
  },
  {
    "Company": "Popsicle",
    "Slogan": "Anything's possible with Popsicle."
  },
  {
    "Company": "Breyers",
    "Slogan": "It's way beyond ice cream."
  },
  {
    "Company": "Haagen-Dazs",
    "Slogan": "Made like no other."
  },
  {
    "Company": "Cornetto",
    "Slogan": "Enjoy the ride, love the ending"
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Chapman's",
    "Slogan": "For the kid in all of us"
  },
  {
    "Company": "Good Humor",
    "Slogan": "Return to the classics"
  },
  {
    "Company": "Perry's Ice Cream",
    "Slogan": "Life is a bowl of Perry's."
  },
  {
    "Company": "Quavers",
    "Slogan": "They're floaty light!"
  },
  {
    "Company": "Lay's",
    "Slogan": "Betcha can't eat just one!"
  },
  {
    "Company": "BelGioioso Cheese",
    "Slogan": "Quality never stops."
  },
  {
    "Company": "Dubliner Cheese",
    "Slogan": "Character in every bite."
  },
  {
    "Company": "The Laughing Cow",
    "Slogan": "Made with laughter."
  },
  {
    "Company": "Leerdammer",
    "Slogan": "Put pleasure first"
  },
  {
    "Company": "Sargento",
    "Slogan": "We're real cheese people"
  },
  {
    "Company": "Callebaut",
    "Slogan": "For the love of chocolate."
  },
  {
    "Company": "Cadbury Dairy Milk",
    "Slogan": "Free the joy."
  },
  {
    "Company": "Milka",
    "Slogan": "Tenderness is inside."
  },
  {
    "Company": "Ghirardelli Chocolate",
    "Slogan": "Moments of timeless pleasure."
  },
  {
    "Company": "Cailler",
    "Slogan": "Pure chocolate. Pure emotion."
  },
  {
    "Company": "Hershey's",
    "Slogan": "Hello happy. Hello Hershey's."
  },
  {
    "Company": "Dove Chocolate",
    "Slogan": "Choose pleasure."
  },
  {
    "Company": "Dreyer's",
    "Slogan": "Share a Scoop. Share a Smile!"
  },
  {
    "Company": "Green & Black's",
    "Slogan": "Bring Milk Chocolate Out Of Its Shell."
  },
  {
    "Company": "Nestles",
    "Slogan": "Nestle. Good Food, Good Life."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Dannon Yogurt",
    "Slogan": "Love it for life."
  },
  {
    "Company": "Ralston Purina",
    "Slogan": "All you add is love."
  },
  {
    "Company": "Pringles",
    "Slogan": "Once you pop, you can't stop."
  },
  {
    "Company": "M&M'S",
    "Slogan": "Melts in Your Mouth, Not in Your Hands."
  },
  {
    "Company": "Heinz",
    "Slogan": "Good Food Every Day."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Papa John's Pizza",
    "Slogan": "Better Ingredients. Better Pizza."
  },
  {
    "Company": "Applebee's",
    "Slogan": "Together is good."
  },
  {
    "Company": "Chili's",
    "Slogan": "Like no place else."
  },
  {
    "Company": "Winky's",
    "Slogan": "Fast Food Cheap"
  },
  {
    "Company": "Wetson's",
    "Slogan": "Buy a bagful."
  },
  {
    "Company": "Royal Castle (restaurant chain)",
    "Slogan": "Fit for a king!"
  },
  {
    "Company": "Red Barn (restaurant)",
    "Slogan": "You'll find more of what you are hankerin'"
  },
  {
    "Company": "Bembos",
    "Slogan": "The best burger and more."
  },
  {
    "Company": "Henry's Hamburgers",
    "Slogan": "Head for Henry's"
  },
  {
    "Company": "Druther's",
    "Slogan": "I'd Ruther Go to Druther's Restaurant."
  },
  {
    "Company": "Five Guys",
    "Slogan": "Best Burger"
  },
  {
    "Company": "Friendly's",
    "Slogan": "Deliciously fun"
  },
  {
    "Company": "Beacon Drive In",
    "Slogan": "we don't change."
  },
  {
    "Company": "Aurelio's Pizza",
    "Slogan": "Aurelio's is Pizza!"
  },
  {
    "Company": "Sukiya",
    "Slogan": "save time and money"
  },
  {
    "Company": "Orange Julius",
    "Slogan": "A Devilish Good Drink"
  },
  {
    "Company": "Fosters Freeze",
    "Slogan": "Everything at Foster's is wonderful to eat"
  },
  {
    "Company": "Kewpee",
    "Slogan": "Mity Nice Hamburger."
  },
  {
    "Company": "Rax Roast Beef",
    "Slogan": "All the right stuff."
  },
  {
    "Company": "Del Taco",
    "Slogan": "Feed the Beast"
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Noodles & Company",
    "Slogan": "Your World Kitchen"
  },
  {
    "Company": "Steak 'n Shake",
    "Slogan": "Famous for Steakburgers"
  },
  {
    "Company": "Spangles",
    "Slogan": "it just tastes better!"
  },
  {
    "Company": "Mary Brown's",
    "Slogan": "Truly Canadian"
  },
  {
    "Company": "Flunch",
    "Slogan": "Y'a que chez Flunch qu'on peut fluncher"
  },
  {
    "Company": "Kirby's Pig Stand",
    "Slogan": "Quick Curb Service"
  },
  {
    "Company": "Round Table Pizza",
    "Slogan": "The Last Honest Pizza"
  },
  {
    "Company": "Brewers Fayre",
    "Slogan": "Pub Food as it Should Be"
  },
  {
    "Company": "Big Boy Restaurants",
    "Slogan": "It's your Big Boy"
  },
  {
    "Company": "Burger Chef",
    "Slogan": "Nowhere else but Burger Chef."
  },
  {
    "Company": "Qdoba",
    "Slogan": "Not just big burritos. Big flavors."
  },
  {
    "Company": "Steak Escape",
    "Slogan": "Americas Favorite Cheesesteak"
  },
  {
    "Company": "White Castle",
    "Slogan": "What you crave."
  },
  {
    "Company": "Outback Steakhouse",
    "Slogan": "No rules, just right."
  },
  {
    "Company": "Red Rooster",
    "Slogan": "It's gotta be red"
  },
  {
    "Company": "Little Caesars",
    "Slogan": "Pizza! Pizza!"
  },
  {
    "Company": "Steers",
    "Slogan": "It Just Tastes Better."
  },
  {
    "Company": "IHOP",
    "Slogan": "Come hungry, leave happy."
  },
  {
    "Company": "Mr. Sub",
    "Slogan": "Yours Since 1968."
  },
  {
    "Company": "Fryer's",
    "Slogan": "Quality You Can Trust."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Kelseys original Roadhouse",
    "Slogan": "Neighbourhood Bar and Grill."
  },
  {
    "Company": "Hungry Jack's",
    "Slogan": "Makes It Better."
  },
  {
    "Company": "Twin Peaks",
    "Slogan": "Eats. Drinks. Scenic Views."
  },
  {
    "Company": "Schlotzsky's",
    "Slogan": "Lotz Better."
  },
  {
    "Company": "Quiznos",
    "Slogan": "Eat Up."
  },
  {
    "Company": "Chuck E. Cheese's",
    "Slogan": "All You Can Play."
  },
  {
    "Company": "Krispy Kreme",
    "Slogan": "Happy All Around."
  },
  {
    "Company": "Harry Ramsden's",
    "Slogan": "World famous fish & chips."
  },
  {
    "Company": "Jimmy John's",
    "Slogan": "Serious delivery."
  },
  {
    "Company": "Checkers and Rally's",
    "Slogan": "Crazy good food."
  },
  {
    "Company": "Tim Hortons",
    "Slogan": "Always fresh."
  },
  {
    "Company": "Ground Round",
    "Slogan": "We've got what you like."
  },
  {
    "Company": "Greggs",
    "Slogan": "Always fresh. Always tasty."
  },
  {
    "Company": "Village Inn",
    "Slogan": "Good Food, Good Feelings."
  },
  {
    "Company": "Wawa",
    "Slogan": "Gottahava Wawa"
  },
  {
    "Company": "Harvey's",
    "Slogan": "To each his burger."
  },
  {
    "Company": "Olive Garden",
    "Slogan": "A Passion for Italy"
  },
  {
    "Company": "Red Lobster",
    "Slogan": "See whatâs cooking."
  },
  {
    "Company": "Wienerschnitzel",
    "Slogan": "The World's most wanted wiener."
  },
  {
    "Company": "Yoshinoya",
    "Slogan": "Tasty, low-priced, and quick"
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Long John Silver's",
    "Slogan": "That's what I like."
  },
  {
    "Company": "Fatburger",
    "Slogan": "The last great hamburger stand."
  },
  {
    "Company": "Jack in the Box",
    "Slogan": "Eat it. Any time."
  },
  {
    "Company": "TCBY",
    "Slogan": "This Countryâs Best Yogurt."
  },
  {
    "Company": "Chicken of the Sea",
    "Slogan": "Itâs Americaâs best-tasting tuna"
  },
  {
    "Company": "Hooters",
    "Slogan": "nearly world famous chicken wings"
  },
  {
    "Company": "Papa Murphy's",
    "Slogan": "Take nâ Bake Pizza."
  },
  {
    "Company": "Denny's",
    "Slogan": "Weâre cooking now."
  },
  {
    "Company": "Auntie Anneâs",
    "Slogan": "Pretzel Perfect"
  },
  {
    "Company": "Sonic Drive-In",
    "Slogan": "Sonic Everywhere"
  },
  {
    "Company": "ConAgra Foods",
    "Slogan": "Food you love"
  },
  {
    "Company": "Ruth's Chris Steakhouse",
    "Slogan": "Lifeâs too short to eat anywhere else."
  },
  {
    "Company": "Sizzler",
    "Slogan": "Great steaks, seafood and salads."
  },
  {
    "Company": "Taco John's",
    "Slogan": "We prepare every order fresh"
  },
  {
    "Company": "Waffle House",
    "Slogan": "Itâs 2am and youâre drunk"
  },
  {
    "Company": "Popeye's Chicken",
    "Slogan": "Party with Popeyeâs"
  },
  {
    "Company": "Vlasic Pickles",
    "Slogan": "Always the perfect pickle"
  },
  {
    "Company": "Bagel Bites",
    "Slogan": "You can prepare them in an instant."
  },
  {
    "Company": "Chick-fil-A",
    "Slogan": "Eat Mor Chikin!"
  },
  {
    "Company": "Rice Krispies",
    "Slogan": "Snap, Crackle, Pop."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  },
  {
    "Company": "Dunkin' Donuts",
    "Slogan": "Loosen up a little."
  },
  {
    "Company": "Church's Chicken",
    "Slogan": "You Bring It Home."
  },
  {
    "Company": "Carl's Jr.",
    "Slogan": "Making people happy through food"
  },
  {
    "Company": "A&W",
    "Slogan": "A & W. All American Food."
  },
  {
    "Company": "Dairy Queen",
    "Slogan": "Do something different."
  },
  {
    "Company": "Arby's",
    "Slogan": "Slicing up freshness."
  },
  {
    "Company": "Wendy's",
    "Slogan": "Do what tastes right."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Taco Bell",
    "Slogan": "Think outside the bun"
  },
  {
    "Company": "Hardee's",
    "Slogan": "Where the food's the star."
  },
  {
    "Company": "Burger King",
    "Slogan": "Have it your way."
  },
  {
    "Company": "Kit Kat",
    "Slogan": "Have a Break, Have a Kit Kat."
  },
  {
    "Company": "Subway",
    "Slogan": "Eat Fresh."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "McDonalds",
    "Slogan": "Iâm Lovinâ It."
  },
  {
    "Company": "Fanta",
    "Slogan": "Drink Fanta, stay Bamboocha."
  },
  {
    "Company": "Colgate",
    "Slogan": "Stronger, healthier gums."
  },
  {
    "Company": "Pizza Hut",
    "Slogan": "Make it great."
  },
  {
    "Company": "Domino's Pizza",
    "Slogan": "Pizza deliver experts."
  },
  {
    "Company": "Head & Shoulders",
    "Slogan": "Live head first."
  },
  {
    "Company": "Ben & Jerry's",
    "Slogan": "Peace, love & ice cream."
  },
  {
    "Company": "Heineken",
    "Slogan": "It's all about the beer."
  },
  {
    "Company": "Gatorade",
    "Slogan": "The Legend Continues."
  },
  {
    "Company": "Milo",
    "Slogan": "MILO Everyday!"
  },
  {
    "Company": "Starbucks",
    "Slogan": "Customize Your Cup."
  },
  {
    "Company": "Audi",
    "Slogan": "Vorsprung durch Technik."
  },
  {
    "Company": "Snickers",
    "Slogan": "Get some nuts!"
  },
  {
    "Company": "NIVEA",
    "Slogan": "More evolved skincare."
  },
  {
    "Company": "Nescafe",
    "Slogan": "It all starts with a NescafÃ©."
  },
  {
    "Company": "KFC(Kentucky Fried Chicken)",
    "Slogan": "Finger Lickin' Good."
  },
  {
    "Company": "Levi's Jeans",
    "Slogan": "Original jeans. original people."
  },
  {
    "Company": "Dove (toiletries)",
    "Slogan": "The secret of beautiful hair."
  },
  {
    "Company": "Lipton",
    "Slogan": "Be more tea."
  },
  {
    "Company": "Pampers",
    "Slogan": "Discover your babyâs world."
  },
  {
    "Company": "Coca-Cola",
    "Slogan": "Taste The Feeling."
  }
];

export default slogans;
